<template>
  <b-card :title="`${$t('General.Update')} ${$t('Warehouse')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('General.ArabicName')"
                label-for="arabicName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.ArabicName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_ar"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.ArabicName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.EnglishName')"
                label-for="englishName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.EnglishName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_en"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.EnglishName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="`${$t('Organization')} ${$t('General.Name')}`"
                rules="required"
              >
                <b-form-group
                  :label="`${$t('Organization')} ${$t('General.Name')}`"
                  label-for="organizationName"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    v-model="organization_id"
                    :state="organization_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="organization_option"
                    :placeholder="`${$t('Organization')} ${$t('General.Name')}`"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Branch')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Branch')"
                  label-for="branch"
                >
                  <v-select
                    v-model="branch_id"
                    :state="branch_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="branch_option"
                    :placeholder="$t('Branch')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('General.Active')"
                label-for="active"
              >
                <b-form-checkbox
                  v-model="active"
                  class="custom-control-primary"
                >
                  {{ $t('General.Active') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('General.Update') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required } from '@validations'
import warehouse from '@/service/warehouse/warehouse'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { heightFade } from '@core/directives/animations'
import organization from '@/service/administration/organization'
import branch from '@/service/administration/branch'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BFormInvalidFeedback,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      name_ar: '',
      name_en: '',
      required,
      organization_id: '',
      organization_option: [],
      branch_id: '',
      branch_option: [],
      active: true,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  async created() {
    await this.getApiSelect()
    this.showOverLay = true
    await warehouse.oneAxios(this.id).then(response => {
      this.name_ar = response.data.name_ar
      this.name_en = response.data.name_en
      this.branch_id = this.branch_option.find(x => x.id === response.data.branch_id)
      this.organization_id = this.organization_option.find(x => x.id === this.branch_id.organization_id)
      this.active = response.data.active === 1
      this.showError = false
    }).catch(error => {
      this.apiErrors = error
      this.showError = true
    }).finally(() => { this.showOverLay = false })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.putApi()
        }
        this.switchLoc()
      })
    },
    async putApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await warehouse.putAxios(this.id, {
        name_ar: this.name_ar,
        name_en: this.name_en,
        active: this.active,
        branch_id: this.branch_id.id,
        user_id: user.id,
      }).then(() => {
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => { this.showOverLay = false })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await branch.getAxios().then(response => {
        this.branch_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await organization.getAxios().then(response => {
        this.organization_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>
